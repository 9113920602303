import React, { FC } from 'react'
import { message, Tooltip } from 'antd'

import { numberWithSeparator } from '../../../../helpers/number.helper'
import { TravelSeatingGroup } from '../../types'

import { typeSeat } from './typeSeat'

import styles from './styles.module.css'

export const SeatingGroup: FC<TravelSeatingGroup> = ({
  travel,
  travelGroup,
  setSelectedTravel,
  active,
  user,
}) => {
  const travelExist = travelGroup.find(
    el => typeSeat[el.seat_class?.toLowerCase()] === travel.seat_class,
  )

  if (
    travelExist &&
    travel.seat_class === typeSeat[travelExist.seat_class?.toLowerCase()]
  ) {
    travel.available_seats = travelExist.available_seats
    travel.price = travelExist.discount_price ?? travelExist.price
    travel.cabin_number = travelExist.cabin_number ?? travelExist.cabin_number
  } else {
    travel.available_seats = 0
    travel.price = 0
  }

  const onClickHandle = () => {
    if (travelExist)
      if (
        user?.role.includes('ADMIN') ||
        (!user?.role.includes('ADMIN') &&
          user?.seatsAllowed?.includes(travelExist.seat_class))
      )
        setSelectedTravel(travelExist)
      else
        message.warning(
          `No tienes permiso para comprar el tipo ${travelExist.seat_class}`,
        )
  }

  return (
    <>
      {travel.available_seats !== 0 ? (
        <div
          className={active ? styles.activeContainer : styles.centered}
          onClick={onClickHandle}>
          <div className={styles.availableSeatsFont}>
            {travel.icon}
            {travel.available_seats || '0'}
          </div>
          <div className={styles.seatTypeFont}>{travel.seat_class}</div>
          <div className={styles.seatPriceFont}>
            {travel.price !== 0
              ? `$${numberWithSeparator(travel.price)}`
              : '--'}
          </div>
          <div className={styles.seatPriceFont}>
          {travel.cabin_number !== 0
              ? `Piso ${travel.cabin_number}`
              : '--'}
          </div>
          <div
            className={`${styles.activePoint} ${
              active ? styles.active : ''
            }`}></div>
        </div>
      ) : (
        <Tooltip
          placement="top"
          title="No hay asientos disponibles de este tipo...">
          <div className={styles.centered} onClick={onClickHandle}>
            <div className={styles.availableSeatsFont}>
              {travel.icon}
              {travel.available_seats || '0'}
            </div>
            <div className={styles.seatTypeFont}>{travel.seat_class}</div>
            <div className={styles.seatPriceFont}>
              {travel.price !== 0
                ? `$${numberWithSeparator(travel.price)}`
                : '--'}
            </div>
            <div
              className={`${styles.activePoint} ${
                active ? styles.active : ''
              }`}></div>
          </div>
        </Tooltip>
      )}
    </>
  )
}
