import React, { FC, useContext, useEffect, useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useLocation } from '@reach/router'
import { Button, Col, message, Row } from 'antd'
import { navigate } from 'gatsby'
import queryString from 'query-string'

import DiscountBar from '../../../../../components/DiscountBar'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import { travelToIntegrationTravel } from '../../../../../helpers/travel-page/compute.helper'
import { useEventSave } from '../../../../../hooks/analytics.hook'
import {
  BOOKING_TRANSBANK_PAYED,
  HAS_PERCENT_DISCOUNT,
  TRANSBANK_PAYMENT_ERROR,
  TRAVEL_BY_TRANSACTION,
} from '../../../../../queries'
import { getUser, tokenInfo } from '../../../../../services/auth'
import { Travel } from '../../../../../types/page-types/travel-details-page.types'
import { TravelPageContext } from '../../../Provider'
import { QueryString, Seat, Travel as IntegrationTravel } from '../../../types'
import TravelResume from '../../TravelResume'

import Success from './success.inline.svg'

import styles from './styles.module.css'

const TransbankPaymentFinished: FC = () => {
  const { setTransaction, setSearch, setQuotas } = useContext(TravelPageContext)

  console.log('llega al fin pago transbank')

  const [travelGone, settravelGone] = useState<Travel>()
  const [travelReturn, setTravelReturn] = useState<Travel>()
  const [text, setText] = useState<string>('')
  const [showTickets, setShowTickets] = useState(false)

  const { loading: discountLoading, data: userInfo } = useQuery(HAS_PERCENT_DISCOUNT)

  const [finishPayment, { loading: mutationLoading }] = useMutation(BOOKING_TRANSBANK_PAYED)
  const [ErrorPayment] = useMutation(TRANSBANK_PAYMENT_ERROR)

  const subTexts = {
    sended: 'Los boletos se han enviado a tus pasajeros vía correo electrónico.',
    notSended:
        'Antes de continuar, identifica a tus pasajeros para enviarles sus boletos vía correo electrónico.',
    semiSended:
        'Boletos enviados a los pasajeros identificados. Sin embargo, quedan pasajeros por identificar. Identifica tus pasajeros',
  }

  const handleClick = () => {
    navigate(`/app/mis-viajes/${travelGone?.bookingId || travelReturn?.bookingId}`)
  }

  const location = useLocation()

  useEffect(() => {
    const { user } = tokenInfo(getUser() ?? '')
    useEventSave('pago_exitoso_transbank', { user: user.email })
    setTransaction({})
    setSearch({} as QueryString)
    setQuotas([])

    const successURL = queryString.parse(location.href)
    const successURLString = JSON.stringify(successURL)
    const { idtrx } = queryString.parse(location.search)

    console.log('successURLString', successURLString)
    console.log('location query', queryString.parse(location.search))
    console.log('idtrx', idtrx)
    console.log('user email', user.email)

    if (idtrx) {
      console.log('Es pago transbank')
      finishPayment({
        variables: { uuid: idtrx, returnStr: successURLString },
      })
          .then(({ data }) => {
            if (!data || !data.bookingTransbankPayed) {
              console.log('Invalid response from finishPayment')
              throw new Error('Invalid response from finishPayment')
            }
            console.log('data', data)
            const { bookingTransbankPayed } = data
            settravelGone(bookingTransbankPayed.gone)
            setTravelReturn(bookingTransbankPayed.return)
          })
          .catch(err => {
            console.trace('Error in finishPayment', err)
            message.error('Error al procesar el pago. Por favor, intente más tarde.')
          })
    } else {
      console.log('Neither id nor idtrx is defined for ', successURL)
      ErrorPayment({
        variables: {
          returnStr: successURLString,
          userEmail: user.email,
          uuid: '',
        },
      })
          .then(() => {
            console.log('Error reportado exitosamente')
          })
          .catch(err => {
            console.error('Error al reportar el error de pago', err)
            message.error('No se pudo reportar el error de pago.')
          })

    }
  }, [])

  useEffect(() => {
    if (travelGone) {
      const passengerAssigned = travelGone.seats.filter(seat => seat.passenger)
      const showTickets = passengerAssigned.length === travelGone.seats.length
      setShowTickets(showTickets)
      setText(
          showTickets
              ? subTexts.sended
              : passengerAssigned.length === 0
                  ? subTexts.notSended
                  : subTexts.semiSended,
      )
    }
  }, [travelGone, travelReturn])

  const toTravelResume = (travelGone: Travel): QueryString => ({
    origin: travelGone.departureCity,
    destiny: travelGone.destinationCity,
    numberOfSeats: travelGone.seats.length.toString(),
  } as QueryString)

  const renderDiscount = () => {
    if (!userInfo?.hasPercentDiscount) return <span />
    return (
      <div className={styles.discountCard}>
        <DiscountBar />
      </div>
    )
  }

  return (
      <Row justify="center" align="stretch" className={styles.background}>
        {mutationLoading ? (
            <Col>
              <LoadingSpinner size="100px" />
            </Col>
        ) : (
            <Col className={styles.content} xs={22} md={12}>
              <Success />
              <p className={styles.titleFont}>Pago completado, ¡buen viaje!</p>
              <p className={styles.subText}>{text}</p>
              {travelGone && (
                  <TravelResume
                      transaction={{
                        travelGone: travelToIntegrationTravel(
                            travelGone,
                        ) as IntegrationTravel,
                        goneSeats: (travelGone.seats as unknown) as Seat[],
                        travelReturn: travelReturn
                            ? (travelToIntegrationTravel(
                                travelReturn,
                            ) as IntegrationTravel)
                            : undefined,
                        returnSeats: travelReturn
                            ? ((travelReturn.seats as unknown) as Seat[])
                            : undefined,
                      }}
                      travelResume={toTravelResume(travelGone)}
                  />
              )}
              {renderDiscount()}
              <Row justify="center">
                <Col xs={24} md={10} lg={8}>
                  {!showTickets ? (
                      <Button
                          block
                          className={`${styles.button} ${styles.blackButton}`}
                          onClick={handleClick}>
                        Identificar Pasajeros
                      </Button>
                  ) : (
                      <Button
                          block
                          className={`${styles.button} ${styles.blackButton}`}
                          href={`${process.env.GATSBY_API_TICKETS_URI}${travelGone?._id}/${
                              travelReturn?._id ?? ''
                          }`}>
                        descargar e-tickets
                      </Button>
                  )}
                </Col>
                <Col xs={24} md={{ span: 10, push: 1 }} lg={{ span: 8, push: 1 }}>
                  <Button block className={styles.button} href="/app/inicio">
                    Comprar otro viaje
                  </Button>
                </Col>
              </Row>
              <Row justify="center">
                <Col xs={24} md={10} lg={6}>
                  <Button
                      block
                      type="text"
                      href="/app/inicio"
                      className={styles.returnButton}>
                    Volver a inicio
                  </Button>
                </Col>
              </Row>
            </Col>
        )}
      </Row>
  )
}

export default TransbankPaymentFinished
